const TYPES = {
  SELLER: 'seller',
  AFFILIATE: 'affiliate',
  SUPER: 'super',
  SUPER_PARTNER: 'super_partner',
  UNKNOWN: '',
};

const WITH_PROFILE_HEADER = [
  TYPES.SELLER,
  TYPES.SUPER,
  TYPES.SUPER_PARTNER,
];

const WITH_CATEGORY_SUPER = [
  TYPES.SUPER,
  TYPES.SUPER_PARTNER,
];

const WITHOUT_ACTIONS_IN_ESHOPSITEM = [
  TYPES.SELLER,
];

module.exports = {
  TYPES,
  WITH_PROFILE_HEADER,
  WITH_CATEGORY_SUPER,
  WITHOUT_ACTIONS_IN_ESHOPSITEM,
};
