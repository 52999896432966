const React = require('react');

const { useRenderContext } = require('../../pages/home/context');

const WrapperSectionText = (Base) => {
  const Wrapper = (props) => {
    const { isEshops } = useRenderContext();

    return (<Base
      {...props}
      isEshops={isEshops}
    />);
  };

  return Wrapper;
};

module.exports = WrapperSectionText;
