/* global window, document */
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Modal } = require('@andes/modal');
const React = require('react');
const PaymentData = require('./payment-data');

const createURL = (href, { pathname, search, hash }) => {
  const urlParsed = new URL(href);
  urlParsed.pathname = `${urlParsed.pathname}${pathname}`;
  urlParsed.search = search;
  if (hash) {
    urlParsed.hash = hash;
  }
  return urlParsed.toString();
};

class PaymentDataDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onIframeLoad = this.onIframeLoad.bind(this);
    this.state = {
      isOpenModal: false,
      showLoading: false,
      modalUrl: '',
      iframeUrl: '',
    };
  }

  onIframeLoad(e) {
    const { iframeUrl } = this.state;
    const iframe = e.target;
    const hashUrl = new URL(iframeUrl).hash;
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    try {
      if (hashUrl && iframe.contentWindow.document.querySelector(hashUrl)) {
        document.querySelector(
          '.andes-modal-dialog__container',
        ).scrollTop = iframe.contentWindow.document.querySelector(hashUrl).offsetTop;
      }
    } catch (err) { /* do nothing */ }
    this.hideLoading();
  }

  openModal(e) {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      const modalUrl = createURL(window.location.href, {
        pathname: '',
        search: '',
        hash: 'show-modal',
      });
      const iframeUrl = createURL(e.currentTarget.href, {
        pathname: '',
        search: 'modal=true',
        hash: '',
      });
      this.setState({
        isOpenModal: true,
        showLoading: true,
        modalUrl,
        iframeUrl,
        title: e.currentTarget.dataset.title,
      });
    }
  }

  hideModal() {
    this.setState({
      isOpenModal: false,
      showLoading: false,
    });
  }

  hideLoading() {
    this.setState({
      showLoading: false,
    });
  }

  render() {
    const { isOpenModal, title, modalUrl, showLoading, iframeUrl } = this.state;
    return (
      <PaymentData
        openModal={this.openModal}
        logosSize="large"
        {...this.props}
      >
        <Modal
          open={isOpenModal}
          onClose={this.hideModal}
          type="large"
          className="modal"
          title={title}
          url={modalUrl}
        >
          {showLoading && <ProgressIndicatorCircular
            modifier="fullscreen"
            size="large"
          />}
          {modalUrl
            && (
            <iframe
              title={title}
              src={iframeUrl}
              onLoad={this.onIframeLoad}
              scrolling="no"
            />
            )}
        </Modal>
      </PaymentData>
    );
  }
}

module.exports = PaymentDataDesktop;
