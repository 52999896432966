const React = require('react');
const PropTypes = require('prop-types');
const { useRenderContext } = require('../../pages/home/context');

const ContentList = require('@mshops-components-library/content-list').default;

const DesktopShoppingInfo = ({ elements, title, deviceType, title_enabled: titleEnabled }) => {
  const { isEshops } = useRenderContext();
  return (
    <ContentList
      deviceType={deviceType}
      elements={elements}
      title={title}
      titleEnabled={titleEnabled}
      isEshops={isEshops}
    />
  );
};

DesktopShoppingInfo.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string.isRequired,
      button: {
        button_href: PropTypes.string.isRequired,
        button_text: PropTypes.string.isRequired,
      },
      description: PropTypes.string,
      element_title: PropTypes.string,
      image: PropTypes.string,
    }),
  ).isRequired,
  deviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  title_enabled: PropTypes.string,
};

module.exports = DesktopShoppingInfo;
